import cctvImg from "../assets/cctv_img.svg";

import styles from "../styles";

const CCTV = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>CCTV Installation</p>
      <p className={`${styles.para1}`}>
        Advanced Security Through State-of-the-Art Surveillance
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={cctvImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        In the realm of modern security, CCTV installation plays a crucial role,
        and OPS Services is at the forefront of offering advanced surveillance
        solutions. Our CCTV systems are designed to provide comprehensive
        coverage, ensuring the safety and security of your premises. We
        specialize in customizing surveillance setups to meet the specific needs
        of each client.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Tailored Surveillance Systems:
          </span>
          <span className={`${styles.list_para}`}>
            We understand that each property has unique security requirements.
            Our team assesses your space to design a CCTV system that provides
            optimal coverage and security.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>Latest Technology:</span>
          <span className={`${styles.list_para}`}>
            Embracing the latest advancements in surveillance technology, we
            offer high-definition cameras and sophisticated monitoring systems
            that ensure clear and reliable footage.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Professional Installation:
          </span>
          <span className={`${styles.list_para}`}>
            Our technicians are experts in CCTV installation, ensuring that your
            surveillance system is set up seamlessly and functions flawlessly.
            We provide guidance on the best placement of cameras and ensure that
            every angle is covered.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Our commitment to security extends beyond installation. We offer
        maintenance and upgrade services to ensure that your CCTV systems remain
        effective and up-to-date. With our surveillance solutions, you can rest
        assured that your property is monitored and protected around the clock.
      </p>
    </div>
  );
};

export default CCTV;
