import deepImg from "../assets/deep_img.svg";

import styles from "../styles";

const Deep_Cleaning = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Deep Cleaning Services</p>
      <p className={`${styles.para1}`}>
        Thorough and Detail-Oriented Cleaning for Pristine Spaces
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={deepImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Deep cleaning is essential for maintaining a healthy and welcoming
        environment. At [Your Company Name], our deep cleaning services are
        comprehensive and meticulous, covering every corner of your property. We
        use specialized equipment and eco-friendly cleaning agents to ensure a
        deep clean that is effective and safe.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Extensive Cleaning Coverage:
          </span>
          <span className={`${styles.list_para}`}>
            Our deep cleaning services are thorough, addressing areas often
            missed in regular cleaning. We ensure that every part of your space
            is spotlessly clean, from floors and walls to fixtures and
            furnishings.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Eco-Friendly Cleaning Agents:
          </span>
          <span className={`${styles.list_para}`}>
            We use non-toxic and environmentally friendly cleaning products,
            ensuring the safety and health of your occupants and the
            environment.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Professional and Experienced Team:
          </span>
          <span className={`${styles.list_para}`}>
            Our cleaners are trained in advanced cleaning techniques and
            equipped with the latest tools. They are detail-oriented and
            committed to delivering the highest level of cleanliness.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Whether it’s a residential home, a commercial office, or an industrial
        facility, our deep cleaning services revitalize your space, leaving it
        clean, fresh, and hygienic.
      </p>
    </div>
  );
};

export default Deep_Cleaning;
