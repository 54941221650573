import securityImg from "../assets/security_img.svg";
import cleaningImg from "../assets/cleaning_img.svg";
import financeImg from "../assets/finance_img.svg";
import electricianImg from "../assets/electrician_img.svg";
import poolImg from "../assets/pool_img.svg";
import cctvImg from "../assets/cctv_img.svg";
import gardeningImg from "../assets/gardening_img.svg";
import liftImg from "../assets/lift_img.svg";
import deepImg from "../assets/deep_img.svg";
import pipeImg from "../assets/pipe_img.svg";
import woodImg from "../assets/wood_img.svg";
import { ArrowRight, ChevronsDown } from "react-feather";

const Services = () => {

  let {origin} = window.location;

  const redirectButton = (url:string, para: boolean) => {
    return <a href={`${origin}${url}`} target="_blank" className={`flex justify-center sm:justify-start items-center mt-4 ${para? "ml-0" : "sm:ml-4 ml-0"}`}>
    <button className="know_more_button sm:justify-center font-light px-6 py-1 rounded">
      Know More
      <ArrowRight className="ml-2 km_right" />
    </button>
  </a>
  }

  return (
    <div>
      <p className="text-center text-3xl customFont customColor_bg_primary py-2 text-stone-100 rounded">
        Our Services
      </p>
      <div className="flex justify-center">
        <ChevronsDown size={25} className="text-stone-100 animate-bounce mt-7 customColor_bg_primary rounded-full p-1" />
      </div>
      <div className="flex sm:flex-row flex-col mt-6 items-center gap-10 justify-center">
        <div className=" basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Security Services
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            Our security services model is built on our broad range of
            specialist services, which enables us to manage risk, security &
            safety of our customers. We provide different type of security
            persons.
          </p>
          <ul className="tracking-wider text-base text-left sm:text-left sm:text-lg text-stone-900 mt-5 list-disc px-4 pl-10 md:pl-4">
            <li>Commercial Security Services</li>
            <li>Bouncers Services</li>
            <li>Residential Security Services</li>
            <li>Industrial Security Services</li>
            <li>Educational Institutes</li>
            <li>Hotel Security</li>
            <li>Cub Security</li>
            <li>Bank & Corporate Security</li>
            <li>Mall & Multiplexes Security</li>
          </ul>
          {redirectButton("/security", false)}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={securityImg} width={400} />
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col-reverse">
        <div className="basis-1/2 flex items-center justify-center">
          <img src={cleaningImg} width={400} />
        </div>
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Housekeeping
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide housekeeping services like:
          </p>
          <ul className="tracking-wider text-base text-left sm:text-left sm:text-lg text-stone-900 mt-5 list-disc px-4 pl-10 md:pl-4">
            <li>Deployment of both male and female staffs</li>
            <li>Deployment of daily and interim staffs</li>
            <li>Sweeping, Cleaning, Dusting</li>
            <li>Carpet & Upholstery-Shampooing</li>
            <li>Polishing</li>
            <li>Inner as well as outer surface area cleaning</li>
          </ul>
          {redirectButton("/housekeeping", false)}
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row flex-col mt-10 items-center gap-10 justify-center">
        <div className=" basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Society Audit & Accounting
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide end-to-end housing society accounting services right from
            doing data entries, bookkeeping and bill collection to generation of
            monetary reports. Our society accounting team is full of experienced
            cooperative society consultants, legal advisors, and society
            managers. This ensures that we are legally correct, documenting well
            and therefore the housing society is future-ready while maintaining
            the finance.
          </p>
          {redirectButton("/audit", true)}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={financeImg} width={400} />
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col-reverse">
        <div className="basis-1/2 flex items-center justify-center">
          <img src={electricianImg} width={400} />
        </div>
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Electrician
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide our customer the best service for Electrical whether for
            a single house, builder project or for a corporation.
          </p>
          {redirectButton("/electrician", true)}
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col">
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Plumbing
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide our customer the best service for Plumbing, whether for a
            single house, builder project or for a corporation.
          </p>
          {redirectButton("/plumbing", true)}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={pipeImg} width={400} />
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col-reverse">
        <div className="basis-1/2 flex items-center justify-center">
          <img src={woodImg} width={400} />
        </div>
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Carpentry
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide our customer the best service for Carpentry, whether for
            a single house, builder project or for a corporation.
          </p>
          {redirectButton("/carpentry", true)}
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col">
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            CCTV
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide our customer the best service for CCTV installation,
            whether for a single house, builder project or for a corporation.
          </p>
          {redirectButton("/cctv", true)}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={cctvImg} width={400} />
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col-reverse">
        <div className="basis-1/2 flex items-center justify-center">
          <img src={liftImg} width={400} />
        </div>
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Lift Maintenance
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide our customer the best service for Lift Maintenance,
            builder project or for a corporation.
          </p>
          {redirectButton("/lift-management", true)}
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col">
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Water Tank/Swimming Pool
          </p>
          <ul className="tracking-wider text-base text-left sm:text-left sm:text-lg text-stone-900 mt-5 list-disc px-4 pl-10 md:pl-4">
            <li>
              We provide best swimming pool maintenance services and promises to
              clean your swimming pool and make it free of any germs or dirt so
              that it remains in a good condition and you or your family does
              not fall sick while enjoying the pool.
            </li>
            <li>
              Pool cleaning requires a lot of time. It is very extensive and
              tedious. Our staff has the technical know-how to complete the job
              in less time with high efficiency.
            </li>
          </ul>
          {redirectButton("/water", false)}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={poolImg} width={400} />
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col-reverse">
        <div className="basis-1/2 flex items-center justify-center">
          <img src={gardeningImg} width={400} />
        </div>
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Garden Maintenance
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide below garden services:
          </p>
          <ul className="tracking-wider text-base text-left sm:text-left sm:text-lg text-stone-900 mt-5 list-disc px-4 pl-10 md:pl-4">
            <li>Deployment of Gardener</li>
            <li>Deployment of Supervisor</li>
            <li>Plantation</li>
            <li>Maintenance</li>
          </ul>
          {redirectButton("/garden", false)}
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex sm:flex-row items-center gap-10 justify-center flex-col">
        <div className="basis-1/2">
          <p className="sm:text-4xl sm:text-left text-center text-3xl customFont customColor_primary">
            Deep Cleaning
          </p>
          <p className="tracking-wider text-lg text-center sm:text-left sm:text-xl text-stone-900 mt-5">
            We provide our customer the best service for Deep cleaning, whether for a single house, builder project or for a corporation.
          </p>
          {redirectButton("/deep-cleaning", true)}
        </div>
        <div className="basis-1/2 flex items-center justify-center">
          <img src={deepImg} width={400} />
        </div>
      </div>
      <div className="py-6"></div>
    </div>
  );
};

export default Services;
