import woodImg from "../assets/wood_img.svg";

import styles from "../styles";

const Carpentry = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Carpentry Services</p>
      <p className={`${styles.para1}`}>
        Bespoke Carpentry Crafting Your Vision into Reality
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={woodImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Our carpentry services merge traditional craftsmanship with contemporary
        design. Our experienced carpenters are capable of transforming wood into
        beautiful, functional pieces that enhance the aesthetics and utility of
        your space. We pride ourselves on delivering custom carpentry solutions
        that meet the unique needs and preferences of our clients.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Customized Security Solutions:
          </span>
          <span className={`${styles.list_para}`}>
            Our services are tailored to bring your vision to life. Whether it’s
            custom furniture, unique fittings, or intricate wooden structures,
            we collaborate with you to create designs that reflect your personal
            style and functional requirements.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Highly Trained Professionals:
          </span>
          <span className={`${styles.list_para}`}>
            We use only the finest materials in our carpentry projects, ensuring
            durability and elegance. Our selection of woods and finishes are
            chosen to suit the specific needs of each project and to stand the
            test of time.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Advanced Security Systems:
          </span>
          <span className={`${styles.list_para}`}>
            Our carpenters are artisans with a passion for woodworking. They
            combine traditional techniques with modern methods to produce work
            of the highest quality. Attention to detail is paramount in our
            work, ensuring that each piece is not just made but crafted to
            perfection.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        From the initial design to the final installation, our comprehensive
        carpentry services cover all aspects of the process. We are dedicated to
        creating pieces that are not just functional but also beautiful
        additions to your space.
      </p>
    </div>
  );
};

export default Carpentry;
