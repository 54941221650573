import gardeningImg from "../assets/gardening_img.svg";

import styles from "../styles";

const Garden_Maintenance = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Garden Maintenance</p>
      <p className={`${styles.para1}`}>
        Nurturing Beauty and Sustainability in Your Outdoor Spaces
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={gardeningImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Our garden maintenance services are focused on creating and sustaining
        beautiful, healthy outdoor spaces. Whether it's a small private garden
        or a large public landscape, our team of experienced gardeners provides
        comprehensive care and maintenance. We use sustainable practices to
        ensure that your garden not only looks great but is also environmentally
        friendly.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>Complete Garden Care:</span>
          <span className={`${styles.list_para}`}>
            From routine maintenance to seasonal planting, our services cover
            all aspects of garden care. We ensure that your garden remains
            vibrant and healthy throughout the year.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Professional Gardening Team:
          </span>
          <span className={`${styles.list_para}`}>
            Our gardeners are skilled in various aspects of horticulture and
            landscape design. They bring expertise and care to every project,
            ensuring that your garden is meticulously maintained.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Sustainable Practices:
          </span>
          <span className={`${styles.list_para}`}>
            We are committed to sustainability in gardening. Our practices focus
            on eco-friendly methods, such as organic fertilizers and water
            conservation techniques, to nurture your garden in harmony with the
            environment.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        With our garden maintenance services, you can enjoy a beautiful outdoor
        space without the hassle of upkeep. We handle everything from lawn care
        to plant selection, creating a serene and inviting garden that
        complements your property.
      </p>
    </div>
  );
};

export default Garden_Maintenance;
