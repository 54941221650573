import { Carousel } from "react-responsive-carousel";
import { MapPin } from "react-feather";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import cleaning from "../assets/cleaning.png";
import electician from "../assets/electician.png";
import gradening from "../assets/gradening.png";
import plumbing from "../assets/plumbing.png";
import security from "../assets/security.png";
import styles from "../styles";

const Hero = () => {
  return (
    <section id="home" className={`flex flex-col md:flex-row gap-10 ${styles.paddingY}`}>
      <div className={`basis-1/2 flex flex-col justify-center items-center md:items-start`}>
        <p style={{ textShadow: '2px 2px #ffff' }} className={`md:text-4xl text-3xl customFont customColor_primary`}>
          OPS SERVICES
        </p>
        <p className={`md:text-xl text-lg tracking-widest text-stone-700`}>
          Facility Management
        </p>
        <br></br>
        <p
          className={`tracking-wider text-lg text-center md:text-left md:text-xl text-stone-900`}
        >
          Welcome to OPS Services, your trusted partner for premium facility management in Pune, Maharashtra. As a registered provider under the Maharashtra Shops and Commercial Act, 1961, we pride ourselves on delivering tailored solutions marked by excellence.
          <br></br>
          <br></br>
          At OPS Services, we believe in redefining facility management through innovation, expertise, and a customer-centric approach. Join us in building lasting relationships based on trust and quality. Elevate your experience with OPS Services - your go-to solution for top-notch facility services.
        </p>
        <div className="flex items-center justify-center mt-4"><MapPin size={20} color="white" fill="#F50107" /> Pune, Maharashtra</div>
      </div>
      <div className={`basis-1/2 remove_dot`}>
        <div className={``}>
          <Carousel
            infiniteLoop={true}
            stopOnHover={true}
            autoPlay={true}
            showThumbs={false}
            showArrows={false}
          >
            <img className={` h-96 py-10 `} style={{ width: "auto" }} src={cleaning} />
            <img className={` h-96 py-10 `} style={{ width: "auto" }} src={security} />
            <img className={` h-72 py-10 mt-16`} style={{ width: "auto" }} src={electician} />
            <img className={` h-60 py-10 mt-20`} style={{ width: "auto" }} src={gradening} />
            <img className={` h-96 py-10 `} style={{ width: "auto" }} src={plumbing} />
            {/* </div> */}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Hero;
