import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import "./navbar.css";
// import logo from '../assets/logo.png';
import full_logo from "../assets/full_logo.png";
import { ChevronDown } from "react-feather";
import { servicesNavLinks } from "../constants";

const Navbar = () => {

  const currentLocation = useLocation();
  const navigate = useHistory();
  const [pathname, setPathname] = useState("/home");
  const [showServices, setShowServices] = useState(false);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentLocation]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, close it
        setShowServices(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setPathname(currentLocation.pathname);
  }, [currentLocation]);

  const navElement = (name: string, route: string, showBar: boolean) => (
    <React.Fragment key={name}>
      <div className={`w-full text-stone-800 text-left text-sm subLink`}>
        <Link
          className={`${
            pathname === route ? "subLink_highlight" : ""
          } hover:text-white block px-4 py-2 `}
          to={route}
        >
          {name}
        </Link>
      </div>
      {!showBar && <hr className={``} />}
    </React.Fragment>
  );

  return (
    <nav id="navbar" className="xl:px-28 md:px-20 px-6 bg-stone-100 bg-opacity-80">
      <span className="cursor-pointer" onClick={() => navigate.replace("/home") }><img id="mainLogo" alt={"icon"} src={full_logo} className={`h-24`} /></span>
      <div className="flex navMenu">
        <Link
          className={`${pathname === "/home" ? "hoverDummy" : ""} navLink`}
          to={"/home"}
        >
          Home
        </Link>
        <Link
          className={`${pathname === "/about" ? "hoverDummy" : ""} navLink`}
          to={"/about"}
        >
          About Us
        </Link>
        <button
          ref={dropdownRef}
          // onMouseEnter={() => setShowServices(true)}
          onClick={() => setShowServices(!showServices)}
          className={`${
            showServices ||
            servicesNavLinks.map((item) => item.route).includes(pathname)
              ? "hoverDummy"
              : "test"
          } flex items-center gap-2 relative navLink`}
        >
          Services <ChevronDown size={20} />
          <div
            className={`absolute -left-10 top-10 bg-stone-100 drop-shadow-lg transition-opacity w-max ${
              showServices ? "opacity-100 block" : "opacity-0 hidden"
            }`}
          >
            {servicesNavLinks.map((item, index) =>
              navElement(
                item.name,
                item.route,
                index === servicesNavLinks.length - 1 ? true : false
              )
            )}
          </div>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
