import financeImg from "../assets/finance_img.svg";

import styles from "../styles";

const Audit = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Society Audit & Accounting</p>
      <p className={`${styles.para1}`}>
        Expert Financial Management for Housing Societies
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={financeImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Our Society Audit & Accounting services are designed to ensure the
        financial stability and transparency of housing societies. We recognize
        the complexities involved in managing society finances and offer
        comprehensive solutions to handle every aspect effectively. Our team of
        seasoned professionals brings a wealth of experience in cooperative
        society management, legal compliance, and financial accounting.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Full-Spectrum Services:
          </span>
          <span className={`${styles.list_para}`}>
            We provide a complete range of services including detailed
            bookkeeping, efficient bill collection, and accurate financial
            reporting. Our approach ensures that every financial aspect of your
            society is meticulously managed, from routine data entries to the
            generation of complex monetary reports.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>Experienced Team:</span>
          <span className={`${styles.list_para}`}>
            Our security personnel are not just guards; they are experts in risk
            Our team comprises skilled cooperative society consultants, seasoned
            legal advisors, and expert society managers. This diverse expertise
            ensures that your society's financial management is not only
            accurate but also adheres to the latest legal standards and best
            practices.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Legal and Financial Compliance:
          </span>
          <span className={`${styles.list_para}`}>
            We prioritize legal compliance and thorough documentation. Our
            services ensure that your housing society is not just financially
            sound but also prepared for any future legal scrutiny. We keep
            abreast of the latest laws and regulations, ensuring that your
            society's finances are managed in a legally sound manner.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Our goal is to provide housing societies with a sense of security and
        confidence in their financial affairs. By entrusting us with your
        financial management needs, you gain access to expert advice, efficient
        management, and peace of mind, knowing that your society&#39;s finances
        are in capable hands.
      </p>
    </div>
  );
};

export default Audit;
