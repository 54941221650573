import liftImg from "../assets/lift_img.svg";

import styles from "../styles";

const Lift = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Lift Maintenance</p>
      <p className={`${styles.para1}`}>
        Ensuring Safety and Reliability in Vertical Transportation
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={liftImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Our lift maintenance services are centered on ensuring the safety and
        efficiency of your elevators and lifts. We recognize the importance of
        reliable vertical transportation in modern buildings and are committed
        to providing top-tier maintenance services. Our team of skilled
        technicians specializes in all aspects of lift maintenance, from routine
        checks to complex repairs.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Regular Safety Inspections:
          </span>
          <span className={`${styles.list_para}`}>
            Regular inspections are key to maintaining lift safety. We conduct
            thorough examinations to identify and rectify any potential issues
            before they become problems, ensuring your lifts operate smoothly
            and safely.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Emergency Repair Services:
          </span>
          <span className={`${styles.list_para}`}>
            In the event of a malfunction, our team is ready to respond swiftly.
            We offer emergency repair services to minimize downtime and restore
            functionality as quickly as possible.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Preventive Maintenance Programs:
          </span>
          <span className={`${styles.list_para}`}>
            Our preventive maintenance programs are designed to keep your lifts
            in optimal condition, extending their lifespan and preventing
            breakdowns. Regular maintenance not only enhances safety but also
            improves efficiency and reliability.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Our comprehensive approach to lift maintenance ensures that every aspect
        of your elevator's operation is covered. We stay updated with the latest
        technologies and safety standards in lift maintenance, providing
        services that you can trust.
      </p>
    </div>
  );
};

export default Lift;
