import securityImg from "../assets/security_img.svg";

import styles from "../styles";

const Security = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Security Services</p>
      <p className={`${styles.para1}`}>
        Customized, Comprehensive Security Solutions for Every Need
      </p>
      <div className="flex mt-10 items-center justify-center">
        <img src={securityImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Our security services at OPS Services are designed to offer unparalleled
        peace of mind. We recognize the unique security challenges faced by
        different environments, from residential complexes to corporate offices,
        and provide tailored solutions to meet these diverse needs. Our approach
        combines the latest security technologies with a team of highly trained
        professionals, ensuring a robust security framework for your property.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Customized Security Solutions:
          </span>
          <span className={`${styles.list_para}`}>
            We understand that each client has unique security needs. Therefore,
            our services are highly adaptable, offering bespoke security plans
            that align with the specific requirements of your premises, whether
            it's for an individual residence, a bustling commercial center, or a
            high-security corporate environment.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Highly Trained Professionals:
          </span>
          <span className={`${styles.list_para}`}>
            Our security personnel are not just guards; they are experts in risk
            management and crisis response. With rigorous training and a keen
            understanding of security dynamics, they are equipped to handle
            various scenarios, from routine patrols to emergency situations,
            ensuring safety and peace of mind.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Advanced Security Systems:
          </span>
          <span className={`${styles.list_para}`}>
            Leveraging the power of modern technology, our security solutions
            incorporate high-tech surveillance systems, access control
            mechanisms, and efficient communication tools. This integration of
            technology enhances our ability to monitor, respond, and manage
            security effectively.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Our commitment extends beyond just providing security personnel. We
        engage in regular security audits, risk assessments, and emergency
        drills to ensure that our strategies are always up-to-date and
        effective. Whether it's event security, residential patrols, or
        corporate security management, we cover all aspects to ensure that you,
        your assets, and your space are safeguarded against all risks.
      </p>
    </div>
  );
};

export default Security;
