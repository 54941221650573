import electricianImg from "../assets/electrician_img.svg";

import styles from "../styles";

const Electrician = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Electrical Services</p>
      <p className={`${styles.para1}`}>
        Reliable and Efficient Electrical Solutions for Modern Needs
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={electricianImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        Our Electrical Services are tailored to meet the dynamic and evolving
        electrical needs of modern spaces. We understand the critical role that
        electrical systems play in the functionality and safety of a property.
        Our team of certified electricians is equipped to handle a broad
        spectrum of electrical tasks, ensuring that your systems are efficient,
        safe, and up to date.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Wide Range of Services:
          </span>
          <span className={`${styles.list_para}`}>
            Whether it's a simple installation, a complex repair, or an entire
            electrical system upgrade, our services cater to all needs. We
            handle everything from minor electrical fixes to major projects with
            the same level of professionalism and expertise.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Energy-Efficient Solutions:
          </span>
          <span className={`${styles.list_para}`}>
            In an era where energy conservation is crucial, we focus on
            providing solutions that enhance the energy efficiency of your
            electrical systems. Our approaches are designed to reduce energy
            consumption and costs, making your space more sustainable.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Licensed Professionals:
          </span>
          <span className={`${styles.list_para}`}>
            Safety is our top priority. Our electricians are not only skilled
            but also licensed and adhere to strict safety standards. They stay
            updated with the latest electrical codes and technologies, ensuring
            that all work is done safely and effectively.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Our electrical services extend beyond mere repairs and installations. We
        offer comprehensive electrical audits, energy-saving consultations, and
        preventive maintenance programs. This holistic approach ensures that
        your electrical systems are not just functional but also contribute to
        the overall efficiency and safety of your property.
      </p>
    </div>
  );
};

export default Electrician;
