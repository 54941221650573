import pipeImg from "../assets/pipe_img.svg";

import styles from "../styles";

const Plumbing = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Plumbing Services</p>
      <p className={`${styles.para1}`}>
        High-Quality Plumbing for Optimal Functionality
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={pipeImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        We offer expert plumbing services designed to ensure the smooth and
        efficient operation of your plumbing systems. Our skilled plumbers are
        adept at handling a wide range of plumbing tasks, from routine
        maintenance to complex installations. We are committed to providing
        solutions that are not only effective but also sustainable and
        long-lasting.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Rapid Response and Effective Solutions:
          </span>
          <span className={`${styles.list_para}`}>
            We understand the urgency of plumbing issues and offer rapid
            response services. Our team is equipped to address and resolve
            plumbing problems efficiently, minimizing any inconvenience or
            downtime.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>Quality Workmanship:</span>
          <span className={`${styles.list_para}`}>
            Our plumbers are not just technicians; they are craftsmen who take
            pride in their work. We use high-quality materials and employ
            advanced plumbing techniques to ensure that every job is done right
            the first time.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Advanced Plumbing Techniques:
          </span>
          <span className={`${styles.list_para}`}>
            Staying abreast of the latest advancements in the field, we employ
            modern plumbing methods and technologies. This approach not only
            enhances the effectiveness of our solutions but also contributes to
            water conservation and environmental sustainability.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Our comprehensive plumbing services include leak detection, pipe
        repairs, fixture installations, water heater services, and much more.
        Whether it’s a residential home, a commercial building, or an industrial
        facility, our plumbing expertise ensures that your systems function
        seamlessly and efficiently.
      </p>
    </div>
  );
};

export default Plumbing;
