import license2 from "../assets/license2.jpg";

import styles from "../styles";

const About = () => {
  return (
    <div className="pt-44 pb-20">
      <p className={`${styles.header}`}>About Us</p>
      <p className={`${styles.para1}`}>
        Delivering Excellence in Facility Management
      </p>
      <p className={`${styles.para2}`}>
        Welcome to OPS Services, where our commitment to excellence defines
        every service we offer. Established as a premier facility management
        provider, we at OPS Services have carved a niche in delivering
        comprehensive solutions tailored to meet the diverse needs of our
        clients. Based in the heart of Pune, we are proud to be a trusted name
        in facility services, registered under the Maharashtra Shops and
        Commercial Act, 1961.
      </p>
      <div className="flex items-center justify-center mt-6">
        <img src={license2} className={`md:w-1/2 w-11/12`} />
      </div>
      <p className={`${styles.about_header}`}>Our Journey</p>
      <p className={`${styles.about_para}`}>
        OPS Services began with a vision to set new standards in facility
        management. Our journey started with a simple yet powerful goal: to
        achieve total customer satisfaction. Over the years, we have expanded
        our services and expertise, continually adapting to the changing needs
        of our clients and the industry. Today, we stand as a one-stop solution
        for an array of facility management services, ranging from housekeeping
        and security services to specialized offerings like society audit and
        accounting, technical services, and more.
      </p>
      <p className={`${styles.about_header}`}>Our Services</p>
      <p className={`${styles.about_para}`}>
        At OPS Services, we understand that each client has unique requirements.
        That&#39;s why our range of services is as diverse as our clientele.
        From providing top-notch security and housekeeping services to managing
        complex tasks like electrical, plumbing, and carpentry work, we ensure
        every aspect of facility management is covered with professionalism and
        efficiency. Our expertise also extends to advanced solutions such as
        CCTV installation, lift maintenance, and specialized cleaning services
        for water tanks, swimming pools, gardens, and deep cleaning needs.
      </p>
      <p className={`${styles.about_header}`}>Our Commitment</p>
      <p className={`${styles.about_para}`}>
        We are more than just a service provider; we are partners in ensuring
        the smooth and efficient running of your facilities. Our commitment to
        quality is unwavering, and it is reflected in our meticulous attention
        to detail, adherence to safety standards, and a constant pursuit of
        innovation. We pride ourselves on our team of skilled professionals,
        each an expert in their field, dedicated to providing service
        excellence.
      </p>
      <p className={`${styles.about_header}`}>Compliance and Standards</p>
      <p className={`${styles.about_para}`}>
        Compliance with statutory requirements is not just a necessity but a
        responsibility we take seriously. We strictly adhere to ESI &amp; PF
        regulations as per the Contract Labor Act, Maharashtra, ensuring legal
        and ethical standards are met in every aspect of our operations.
        excellence.
      </p>
      <p className={`${styles.about_header}`}>Looking Ahead</p>
      <p className={`${styles.about_para}`}>
        As we continue to grow and evolve, our focus remains steadfast on
        delivering services that are not just effective but also add value to
        your facilities. OPS Services is committed to being a dynamic and
        forward-thinking leader in facility management, constantly striving to
        exceed the expectations of our clients and setting new benchmarks in
        service excellence. excellence.
      </p>
      {/* <p className={`${styles.about_header}`}>Additional Documents</p> */}
      
    </div>
  );
};

export default About;
