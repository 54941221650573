import React from "react";
import { Phone, Copy, Mail, MapPin } from "react-feather";
import toast, { Toaster } from "react-hot-toast";

const Footer = () => {
  const copyHandler = async (number: string, type?: "email") => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(number);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = number;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
    if (type === "email") {
      toast.success("Email Copied!");
    } else {
      toast.success("Phone Number Copied!");
    }
  };

  const redirectHandler = () => {
    let latitude = 18.483900; 
    let longitude = 73.798843; 

    let mapUrl = "https://www.google.com/maps?q=" + latitude + "," + longitude;
    window.open(
      mapUrl,
      '_blank' // <- This is what makes it open in a new window.
    );
  };

  return (
    <div
      className="divCenter flex-col text-white pt-16 w-full relative"
      style={{ backgroundColor: "#008C8E" }}
    >
      <div className="w-screen h-96 contact_us absolute opacity-20 "></div>
      <div className="divCenter w-full flex-col px-4 z-10">
        <div className="flex items-center flex-col justify-center">
          <h2
            data-aos="fade-up"
            className="text-center font-normal mb-6 text-base lg:text-xl"
            style={{ letterSpacing: "0.5em" }}
          >
            Contact Us
          </h2>
          <Phone />
          <div className={`flex md:flex-row flex-col gap-10 items-center mt-6`}>
            <h1
              onClick={() => copyHandler("8796387512")}
              data-aos="fade-up"
              className="text-center border border-white cursor-pointer flex items-center gap-4 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"
            >
              8796387512
              <Copy size={15} />
            </h1>
            <h1
              onClick={() => copyHandler("9209323526")}
              data-aos="fade-up"
              className="text-center border border-white cursor-pointer flex items-center gap-4 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"
            >
              9209323526
              <Copy size={15} />
            </h1>
          </div>
        </div>
        <div className="flex items-center flex-col mt-10 justify-center">
          <Mail />
          <div
            data-aos="fade-up"
            className="text-center font-base md:text-xl lg:text-xl text-base divCenter mt-6"
          >
            <h1
              onClick={() => copyHandler("ops.pune23@gmail.com", "email")}
              data-aos="fade-up"
              className="text-center border border-white cursor-pointer flex items-center gap-4 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"
            >
              ops.pune23@gmail.com
              <Copy size={15} />
            </h1>
          </div>
        </div>
        <div className="flex items-center flex-col mt-10 justify-center">
          <MapPin />
          <div
            data-aos="fade-up"
            className="text-center font-base md:text-xl lg:text-xl text-base divCenter mt-6"
          >
            <h1
              onClick={redirectHandler}
              data-aos="fade-up"
              className="text-center border border-white cursor-pointer flex items-center gap-4 font-base md:text-xl lg:text-xl text-base tracking-widest neoButtonRed"
            >
              Open in Google maps
            </h1>
          </div>
          <div className="text-center cursor-text font-base md:text-base lg:text-base text-sm divCenter mt-6 md:px-16 px-8">
            Sr.No 134/4/2 Shri Datta Niwas, Omkar colony lane 1 , Near Popular
            Nagar Nav Graha Hanuman Mandir, Warje Malwadi, Pune-411058
          </div>
        </div>
      </div>
      <a
        className="mt-16 cursor-pointer text-sm"
        href="https://storyset.com/business"
      >
        Business illustrations by Storyset
      </a>
      <p
        className="mb-2 text-center bottom-0 font-light text-sm md:w-2/3 tracking-wider mx-2"
      >
        © Copyright {new Date().getFullYear()} OPS service, Inc. All rights
        reserved. Various trademarks held by their respective owners.
      </p>

      <Toaster position="top-right" />
    </div>
  );
};

export default Footer;
