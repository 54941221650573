import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";

import full_logo from "../assets/full_logo.png";
import { ChevronDown, X } from "react-feather";
import { servicesNavLinks } from "../constants";

const DropMenu = () => {
  const currentLocation = useLocation();
  const navigate = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentLocation]);

  const [menu, setMenu] = useState(false);

  return (
    <>
      <div
        onClick={() => setMenu(!menu)}
        className="fixed lg:left-8 left-4 top-8 z-50"
      >
        <div className={`h-4 w-6 lg:w-8 ${menu ? "open" : ""}`} id="menu-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="icon_main absolute w-full flex top-0 justify-center">
        <span onClick={() => navigate.replace("/home")}>
          <img id="mainLogo" alt={"icon"} src={full_logo} className={`h-24`} />
        </span>
      </div>
      <Drawer
        open={menu}
        size={300}
        onClose={() => setMenu(false)}
        direction="left"
        className=""
      >
        <div className="menu h-screen w-full flex flex-col py-10 pl-10 pr-4 text-xl">
          <p className="mb-4 w-full flex justify-between items-center tracking-widest underline text-stone-900 ">
            Menu{" "}
            <span className="customColor_bg_primary rounded-full p-1">
              <X onClick={() => setMenu(false)} size={15} color="white" />
            </span>
          </p>
          <div className="flex flex-col overflow-scroll">
            <Link
              to={"/home"}
              onClick={() => setMenu(false)}
              className={`link ${
                window.location.pathname === "/home"
                  ? "customColor_primary"
                  : "text-stone-900"
              }`}
            >
              Home
            </Link>
            <hr />
            <Link
              to={"/about"}
              onClick={() => setMenu(false)}
              className={`link ${
                window.location.pathname === "/about"
                  ? "customColor_primary"
                  : "text-stone-900"
              }`}
            >
              About Us
            </Link>
            <hr />
            <div>
              <button
                onClick={() => setMenu(false)}
                className={`link text-stone-900 flex items-center`}
              >
                Our Services
                <ChevronDown />
              </button>
              <ul className={"text-lg pl-4 py-4"}>
                {servicesNavLinks.map((item) => (
                  <>
                    <li className="text-base">
                      <Link
                        to={item.route}
                        onClick={() => setMenu(false)}
                        className={`link w-full block ${
                          window.location.pathname === item.route
                            ? "customColor_primary"
                            : "text-stone-900"
                        }`}
                      >
                        {item.name}
                      </Link>
                    </li>
                    <hr />
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DropMenu;
