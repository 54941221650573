import poolImg from "../assets/pool_img.svg";

import styles from "../styles";

const Water = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>
        Water Tank & Swimming Pool Maintenance
      </p>
      <p className={`${styles.para1}`}>
        Expert Care for Hygienic and Safe Water Facilities
      </p>
      <div className="mt-10 flex items-center justify-center">
        <img src={poolImg} width={400} />
      </div>
      <p className={`${styles.para2}`}>
        We understand the importance of clean and hygienic water facilities. Our
        water tank and swimming pool maintenance services are designed to ensure
        the cleanliness and safety of your water storage and recreational areas.
        We use advanced cleaning and treatment methods to maintain the highest
        standards of hygiene.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Comprehensive Cleaning and Maintenance:
          </span>
          <span className={`${styles.list_para}`}>
            Our team is equipped with the tools and expertise to perform
            thorough cleaning of water tanks and swimming pools. We remove
            contaminants and ensure that your water is clean and safe for use.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Advanced Treatment Methods:
          </span>
          <span className={`${styles.list_para}`}>
            We employ modern treatment techniques to maintain water quality. Our
            methods are effective in keeping your water facilities free from
            germs and harmful substances.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Efficient and Thorough Service:
          </span>
          <span className={`${styles.list_para}`}>
            Our staff is trained in efficient cleaning methods, ensuring that
            the job is done thoroughly and in a timely manner. We focus on
            providing services that are both effective and convenient for our
            clients.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        Whether it’s a residential swimming pool or a commercial water tank, our
        maintenance services ensure that your water facilities are in pristine
        condition, providing a safe and enjoyable environment for all users.
      </p>
    </div>
  );
};

export default Water;
