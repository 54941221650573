const styles = {
    boxWidth: "w-full px-4 sm:px-24 lg:px-36",
  
    heading2: "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    paragraph: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",
  
    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
  
    paddingX: "",
    paddingY: "pt-40 pb-20",
    padding: "sm:px-16 px-6 sm:py-12 py-4",
  
    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",
    header: "lg:text-6xl sm:text-5xl text-4xl text-center customFont capitalize customColor_primary tracking-wider",
    para1: "sm:text-xl lg:text-2xl text-lg mt-2 tracking-widest customFont text-center sm:px-10 lg:px-20 px-4 text-stone-900",
    para2: "sm:text-xl lg:text-2xl text-lg mt-12 font-light text-center text-stone-900 sm:px-10 lg:px-20 px-4",
    list_container: "lg:text-xl text-lg mt-12 font-light text-center text-stone-900 px-2 lg:px-10 md:px-32",
    list_item: "flex items-center flex-col my-10",
    list_header: "font-semibold",
    list_para: "mt-1",
    last_para: "text-xl mt-12 font-light text-center text-stone-900 sm:px-10 lg:px-20 px-2",
    about_header: "sm:text-lg lg:text-xl text-base mt-10 font-semibold text-center text-stone-900 sm:px-10 lg:px-20 px-4",
    about_para: "sm:text-lg lg:text-xl text-base mt-4 font-light text-center text-stone-900 sm:px-10 lg:px-20 px-4"
  }
  
  export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY}`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,
  
    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,
  
    sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
  }
  
  export default styles;