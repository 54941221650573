export const servicesNavLinks = [
  {
    name: "Security Services",
    route: "/security",
  },
  {
    name: "Housekeeping",
    route: "/housekeeping",
  },
  {
    name: "Society Audit & Accounting",
    route: "/audit",
  },
  {
    name: "Electrician",
    route: "/electrician",
  },
  {
    name: "Plumbing",
    route: "/plumbing",
  },
  {
    name: "Carpentry",
    route: "/carpentry",
  },
  {
    name: "CCTV",
    route: "/cctv",
  },
  {
    name: "Lift Maintenance",
    route: "/lift-management",
  },
  {
    name: "Water Tank/Swimming Pool",
    route: "/water",
  },
  {
    name: "Garden Maintenance",
    route: "/garden",
  },
  {
    name: "Deep Cleaning",
    route: "/deep-cleaning",
  },
];
