import cleaningImg from "../assets/cleaning_img.svg";

import styles from "../styles";

const Housekeeping = () => {
  return (
    <div className={"pt-44 pb-20"}>
      <p className={`${styles.header}`}>Housekeeping</p>
      <p className={`${styles.para1}`}>
        Exemplary Cleaning Services for Pristine Environments
      </p>
      <div className="mt-10 flex items-center justify-center">
          <img src={cleaningImg} width={400} />
        </div>
      <p className={`${styles.para2}`}>
        At OPS our housekeeping services go beyond mere cleaning. We strive to
        create environments that are not just clean but truly pristine,
        contributing to a healthier and more welcoming atmosphere. Our team of
        dedicated professionals is equipped to handle a variety of cleaning
        tasks, ensuring that every inch of your space is meticulously
        maintained.
      </p>
      <ul className={`${styles.list_container}`}>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Diverse Cleaning Services:
          </span>
          <span className={`${styles.list_para}`}>
            Our comprehensive suite of services includes daily cleaning
            routines, deep cleaning sessions, and specialized tasks such as
            carpet shampooing, upholstery cleaning, and high-grade polishing. We
            cater to both residential and commercial spaces, tailoring our
            services to meet the specific needs of each client.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>
            Eco-Friendly Approach:
          </span>
          <span className={`${styles.list_para}`}>
            In our commitment to sustainability, we utilize eco-friendly and
            non- toxic cleaning agents, ensuring a safe environment for both our
            clients and the planet. Our methods are not only effective but also
            environmentally responsible, reflecting our dedication to green
            practices.
          </span>
        </li>
        <li className={`${styles.list_item}`}>
          <span className={`${styles.list_header}`}>Expert Team:</span>
          <span className={`${styles.list_para}`}>
            Our housekeeping staff are not just cleaners; they are trained
            professionals who understand the nuances of various cleaning
            techniques. They are equipped with state- of-the-art tools and
            products, ensuring high efficiency and superior quality in every
            task they undertake.
          </span>
        </li>
      </ul>
      <p className={`${styles.last_para}`}>
        We recognize the importance of a clean environment in enhancing quality
        of life and productivity. Therefore, we focus on delivering services
        that not only clean but also rejuvenate your space. Our team is
        committed to detail, ensuring that from the larger surfaces to the
        smallest nooks and crannies, every aspect of your environment is
        impeccably maintained.
      </p>
    </div>
  );
};

export default Housekeeping;
