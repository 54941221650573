import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import styles from "./styles";
import Hero from "./view/Hero";
import Navbar from "./view/Navbar";
import Services from "./view/Services";
import Security from "./view/Security";
import Footer from "./view/Footer";
import Housekeeping from "./view/Housekeeping";
import Audit from "./view/Audit";
import Electrician from "./view/Electrician";
import Deep_Cleaning from "./view/Deep_Cleaning";
import Garden_Maintenance from "./view/Garden_Maintenance";
import Water from "./view/Water";
import CCTV from "./view/CCTV";
import Carpentry from "./view/Carpentry";
import Plumbing from "./view/Plumbing";
import DropMenu from "./view/DropMenu";
import Lift from "./view/Lift";
import About from "./view/About";

function App() {

  return (
    <Router>
      <div className="w-full overflow-hidden bg-stone-100">
        <div className={`${styles.paddingX}`}>
          <div className={`${styles.boxWidth} md:block hidden`}>
            <Navbar />
          </div>
          <div className={`md:hidden block`}>
            <DropMenu />
          </div>
        </div>
        <Switch>
          <Route path="/security">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Security />
              </div>
            </div>
          </Route>
          <Route path="/housekeeping">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Housekeeping />
              </div>
            </div>
          </Route>
          <Route path="/audit">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Audit />
              </div>
            </div>
          </Route>
          <Route path="/electrician">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Electrician />
              </div>
            </div>
          </Route>
          <Route path="/plumbing">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Plumbing />
              </div>
            </div>
          </Route>
          <Route path="/carpentry">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Carpentry />
              </div>
            </div>
          </Route>
          <Route path="/cctv">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <CCTV />
              </div>
            </div>
          </Route>
          <Route path="/lift-management">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Lift />
              </div>
            </div>
          </Route>
          <Route path="/water">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Water />
              </div>
            </div>
          </Route>
          <Route path="/garden">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Garden_Maintenance />
              </div>
            </div>
          </Route>
          <Route path="/deep-cleaning">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Deep_Cleaning />
              </div>
            </div>
          </Route>
          <Route path="/about">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <About />
              </div>
            </div>
          </Route>
          <Route path="/home">
            <div className={`${styles.flexStart}`}>
              <div className={`${styles.boxWidth}`}>
                <Hero />
                <Services />
              </div>
            </div>
          </Route>
          <Redirect to="/home" />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
